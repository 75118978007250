<template>
  <div
    ref="contentWrapper"
    class="rich-text"
    :class="[`columns-${columns}`]"
    v-html="content"
  />
</template>

<script setup lang="ts">
import iconDownload from "~/assets/svg/i_download.svg?raw";
import iconPlay from "~/assets/svg/play.svg?raw";
import iconArrowRight from "~/assets/svg/i_arrow-right.svg?raw";
import iconInfo from "~/assets/svg/info.svg?raw";
import { getFileExtensionFromUrl } from "~/utils/route";

const props = defineProps({
  content: {
    type: String,
    default: null,
  },
  columns: {
    type: Number,
    default: 1,
  },
  formatFileStyle: {
    type: Boolean,
    default: true,
  },
});

const contentWrapper = ref(null);

const tableClasses = {
  leftScrim: "show-left-border",
  rightScrim: "show-right-border",
};

const wrapTableElementByDiv = () => {
  const tables = contentWrapper.value.querySelectorAll("table");
  tables.forEach((tableEl) => {
    const parentElement = tableEl.parentNode;
    const siblings = Array.from(parentElement.children);
    const elementIndex = siblings.indexOf(tableEl);

    const tableWrapper = document.createElement("div");
    tableWrapper.classList.add("table-wrapper");
    tableWrapper.appendChild(tableEl);

    const tableEdgeLeft = document.createElement("div");
    tableEdgeLeft.classList.add("table-edge-left");
    const tableEdgeRight = document.createElement("div");
    tableEdgeRight.classList.add("table-edge-right");

    tableWrapper.insertBefore(tableEdgeLeft, tableEl);
    tableWrapper.appendChild(tableEdgeRight);

    const tableContainer = document.createElement("div");
    tableContainer.classList.add("table-container");
    tableContainer.appendChild(tableWrapper);

    if (elementIndex !== -1) {
      parentElement.insertBefore(tableContainer, siblings[elementIndex + 1]);
      if (tableContainer && tableWrapper) {
        const tableObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const scrimClass =
                entry.target === tableEdgeLeft
                  ? tableClasses.leftScrim
                  : tableClasses.rightScrim;

              tableContainer.classList.toggle(
                scrimClass,
                !entry.isIntersecting,
              );
            });
          },
          { root: tableWrapper },
        );

        tableObserver.observe(tableEdgeLeft);
        tableObserver.observe(tableEdgeRight);
      }
    }
  });
};

const sanitizeHyperlinkElements = () => {
  const buttons = contentWrapper.value.querySelectorAll(".button");
  const hyperlinks = contentWrapper.value.querySelectorAll("a");
  if (hyperlinks?.length) {
    hyperlinks.forEach((hyperlink) => {
      hyperlink.removeAttribute("target");
    });
  }
  if (buttons?.length) {
    buttons.forEach((button) => {
      button.removeAttribute("style");
    });
  }
};

const sanitizeNotificationElements = () => {
  const elements = contentWrapper.value.querySelectorAll(
    ".helper-notification",
  );
  if (elements?.length) {
    elements.forEach((element) => {
      element.removeAttribute("style");
      const content = element.innerHTML;
      const span = document.createElement("span");
      span.innerHTML = content;

      element.innerHTML = "";
      const icon = new DOMParser().parseFromString(iconInfo, "application/xml");
      element.appendChild(
        element.ownerDocument.importNode(icon.documentElement, true),
      );
      element.appendChild(span);
    });
  }
};

const initializeFileHyperlinks = () => {
  let hyperlinks = contentWrapper.value.querySelectorAll(
    "a:not(.button):not([href^='tel:']):not([href^='mailto:']):not(.helper-notification a)",
  );
  if (hyperlinks?.length) {
    hyperlinks.forEach((hyperlink) => {
      const href = hyperlink.href;
      if (props.formatFileStyle && href && getFileExtensionFromUrl(href)) {
        const text = hyperlink.textContent;
        const span = document.createElement("span");
        span.textContent = text;

        hyperlink.innerHTML = "";
        const icon = new DOMParser().parseFromString(
          iconDownload,
          "application/xml",
        );
        hyperlink.appendChild(
          hyperlink.ownerDocument.importNode(icon.documentElement, true),
        );
        hyperlink.appendChild(span);

        hyperlink.classList.add("button", "button-secondary", "button-file");
        hyperlink.setAttribute("download", text);
      } else {
        hyperlink.classList.add("link");
      }
    });
  }
  hyperlinks = contentWrapper.value.querySelectorAll("a.button-cms-play");
  if (hyperlinks?.length) {
    hyperlinks.forEach((hyperlink) => {
      const text = hyperlink.textContent;
      const span = document.createElement("span");
      span.textContent = text;
      span.classList.add("ml-2");

      hyperlink.innerHTML = "";
      const icon = new DOMParser().parseFromString(iconPlay, "application/xml");
      hyperlink.appendChild(
        hyperlink.ownerDocument.importNode(icon.documentElement, true),
      );
      hyperlink.appendChild(span);
    });
  }
  hyperlinks = contentWrapper.value.querySelectorAll("a.button-cms-arrow");
  if (hyperlinks?.length) {
    hyperlinks.forEach((hyperlink) => {
      const text = hyperlink.textContent;
      const span = document.createElement("span");
      span.textContent = text;
      span.classList.add("mr-2");

      hyperlink.innerHTML = "";
      const icon = new DOMParser().parseFromString(
        iconArrowRight,
        "application/xml",
      );
      hyperlink.appendChild(span);
      hyperlink.appendChild(
        hyperlink.ownerDocument.importNode(icon.documentElement, true),
      );
    });
  }
};

onMounted(() => {
  if (contentWrapper.value) {
    wrapTableElementByDiv();
    sanitizeHyperlinkElements();
    initializeFileHyperlinks();
    sanitizeNotificationElements();
  }
});
</script>

<style scoped>
.columns-8 {
  @apply columns-1 sm:columns-2 md:columns-4 xl:!columns-8;
}
.columns-7 {
  @apply columns-1 sm:columns-2 md:columns-4 xl:!columns-7;
}
.columns-6 {
  @apply columns-1 sm:columns-2 md:columns-3 xl:!columns-6;
}
.columns-5 {
  @apply columns-1 sm:columns-2 md:columns-3 xl:!columns-5;
}
.columns-4 {
  @apply columns-1 sm:columns-2 md:columns-2 xl:!columns-4;
}
.columns-3 {
  @apply columns-1 sm:columns-2 md:columns-2 xl:!columns-3;
}
.columns-2 {
  @apply columns-1 sm:!columns-2;
}
</style>
