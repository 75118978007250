export const endingSlash = (url) => {
  return url?.endsWith?.("/") ? url : url + "/";
};

export const withoutEndingSlash = (url) => {
  return url?.endsWith?.("/") ? url.slice(0, -1) : url;
};

export function getInternalUrl(url) {
  const route = useRoute();
  return endingSlash(
    `/${route.params?.lang}/${route.params?.cinemaslug}/${url}`,
  );
}

export const getFileExtensionFromUrl = (url) => {
  try {
    return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1];
  } catch (ignored) {
    return false;
  }
};
